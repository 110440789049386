.form-control-select,
.form-control-select:focus {
    border: 0;
    background: var(--light);
    padding: 5px 10px;
    box-shadow: none !important;
    position: relative;
    border: 1px solid var(--border);
    border-radius: 5px;
}

.form-control-select:focus-visible {
    border: 1px solid var(--border) !important;
    outline: none; /* Remove the outline */
}

.form-control-select::after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border: solid transparent;
    border-width: 6px 6px 0 6px;
    border-top-color: var(--dark);
    pointer-events: none;
}