.chat-head-container {
    position: fixed !important;
    right: 0;
    bottom: 0;
    /* margin-right: 100px;
    margin-bottom: 50px; */
    margin-right: 25px;
    margin-bottom: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: var(--primary);
    border-radius: 50% !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px; */
}

/* .widget-chat-container {
    position: fixed !important;
    right: 0;
    bottom: 0;
} */

.widget-chat-box {
    position: fixed !important;
    z-index: 9;
    right: 0;
    bottom: 0;
    /* margin-right: 100px; */
    margin-right: 25px;
    margin-bottom: 87px !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}


/* Chat Box */
.msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 50px);
    padding: 25px 25px 30px 25px;
    overflow-y: auto;
    max-height: 230px;
}

.invitation-msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 50px);
    padding: 25px 0 30px 0;
    overflow-y: auto;
    max-height: 230px;
}

.collab-msger {
    height: 230px !important;
    overflow-y: auto;
}

.msger-chat {
    flex: 1;
    min-height: 230px;
    width: 275px;
    /* max-width: 275px; */
}

.collab-msger::-webkit-scrollbar {
    width: 6px;
}

.collab-msger::-webkit-scrollbar-track {
    background: var(--light);
}

.collab-msger::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.msger-inputarea {
    display: flex;
}

.msger-input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.msger-send-btn {
    border: 0;
    color: var(--primary);
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--white);
    margin-left: 10px;
}

.resize-none {
    resize: none;
}

.chat-count-cont {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--danger);
    position: absolute;
    top: 0;
    right: 0;
    color: var(--white);
}




.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.collab-msg-text {
    font-size: 14px;
}

.msg-bubble {
    max-width: 450px;
    padding: 15px;
    border-radius: 15px;
    background: var(--left-msg-bg);
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.msg-info-name {
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
}

.msg-info-time {
    font-size: 10px;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: #d1e7fb;
    color: #393C41;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}