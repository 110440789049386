.stamp-scroll {
    overflow-x: auto;
}

.stamp-container {
    display: grid; /* Use a grid layout for precise control */
    grid-template-columns: repeat(5, 1fr); /* 5 equal columns */
    gap: 10px; /* Space between the circles */
    justify-items: center; /* Center items in each column */
}

.stamp-bullet {
    width: 50px; /* Fixed width for the circle */
    height: 50px; /* Same as width for a perfect circle */
    border-radius: 50%; /* Ensures the shape is a circle */
    border: 2px solid var(--primary); /* Circle border */
    display: flex;
    justify-content: center;
    align-items: center;
}

.stamp-bullet-inactive {
    opacity: 40%;
}

.stamp-bullet-active {
    background-color: var(--info);
}

/* .card-bg-custom {
    background-image: url('../../images/flora-logo.png');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
} */