/* Background color utility classes */
.bg-header {
    background-color: var(--header) !important;
}

.bg-primary {
    background-color: var(--primary) !important;
    color: var(--text-light);
}

.bg-secondary {
    background-color: var(--secondary) !important;
    color: var(--text-light);
}

.bg-success {
    background-color: var(--success) !important;
    color: var(--text-light);
}

.bg-danger {
    background-color: var(--danger) !important;
    color: var(--text-light);
}

.bg-warning {
    background-color: var(--warning) !important;
    color: var(--text-dark);
}

.bg-info {
    background-color: var(--info) !important;
    color: var(--text-light);
}

.bg-light {
    background-color: var(--light) !important;
    color: var(--text-dark);
}

.bg-dark {
    background-color: var(--dark) !important;
    color: var(--text-light);
}

.bg-card-gray {
    background-color: var(--card-bg) !important;
}