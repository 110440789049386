@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('fonts/font-size.css');
@import url('fonts/font-weight.css');

/** Uncomment this import if needed */
@import url('form/form-control-select.css');
@import url('background/container.css');
@import url('hr/hr-or.css');
@import url('hr/hr.css');
/* @import url('scrollbar/scrollbar.css'); */
@import url('loading/global-loading.css');
@import url('custom/custom-style.css');
@import url('custom/table.css');
@import url('border/border.css');
@import url('custom/progress-bar-ratings.css');
@import url('custom/stamp.css');
@import url('custom/chat.css');
/* Bootstrap overrides */
@import url('overrides/button.css');
@import url('overrides/background.css');
@import url('overrides/text.css');
@import url('overrides/tabs.css');

:root {
    --primary: #13873E;
    --secondary: #f9f9f9;
    --success: #28a745;
    --danger: #dc3545;
    --warning: #ffc107;
    --info: #17a2b8;
    --light: #f8f9fa;
    --dark: #000000;
    --text-light: #fff;
    --text-dark: #000000;
    --header: #ffffff;
    --progress-yellow: #ffd700;

    --border: #ADADAD;
    --low-prim: #cbdfc68f;
    --white: #fff;
    --gray: #808080;
    --card-bg: #d9d9d9;

    --font-primary: 'Poppins';
}

body {
    font-family: var(--font-primary) !important;
    background-color: var(--secondary) !important;
    color: var(--dark) !important;
}

p {
    margin: 0 !important;
}

a {
    text-decoration: none;
    color: var(--text-dark);
    transition: 0.3s;
}

section {
    padding: 0 1rem 0 1rem;
}

.a-hover:hover {
    opacity: 70% !important;
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-wrap {
    overflow-wrap: break-word !important;
}

.equal-height-col {
    display: flex !important;
    flex-direction: column !important;
}

.divider-header {
    height: 10px;
    width: 100%;
    background-color: var(--gray);
}

.border-bottom-header {
    border-bottom: 10px solid var(--primary);
}


.no-border {
    border: none !important;
}

.floating-close-container {
    position: absolute;
    right: 10px;
    top: 10px;
}

.floating-close {
    width: 20px !important;
    height: 20px !important;
    background-color: var(--danger);
    color: var(--white);
    border: 1px solid var(--border);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floating-close:hover {
    opacity: 30px !important;
}

.custom-modal-size .modal-dialog {
    width: 100%;
    max-width: 100%;
}

@media (min-width: 992px) {  /* Bootstrap's lg breakpoint */
    .custom-modal-size .modal-dialog {
        width: 35%;
        max-width: 35%;
    }
}

.red-dot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: var(--white);
    border-radius: 50%;
    background-color: var(--danger);
}

.text-underlined {
    text-decoration: underline;
}
  
.special-bg {
    background-color: var(--primary);
    color: var(--light)
}

@media print {
    .print-header, .print-only {
        visibility: visible;
    }
    .print-header {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
    }
}
