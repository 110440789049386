.auth-hr:after {
    background: #f9f9f9;
    content: "or";
    padding: 0 3px;
    position: relative;
    top: -13px;
}

.auth-hr {
    border: none;
    border-top: 1px solid #333;
    color: #333;
    overflow: visible;
    text-align: center;
    height: 1px;
}