.progress-bar-container-cus {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.progress-bar-cus {
    display: flex;
    align-items: center;
    gap: 5px;
}

.rating-label-cus {
    font-size: 14px;
    color: var(--dark);
    flex: 0 0 20px;
}

.progress-cus {
    flex: 1;
    background-color: var(--gray);
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.progress-bar-fill-cus {
    background-color: var(--progress-yellow);
    height: 10px;
    border-radius: 5px;
}
