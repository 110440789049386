/* For the active tab (when selected) */
.nav-tabs .nav-link.active {
    color: var(--primary) !important; /* Primary color */
    background-color: var(--white); /* Optional: Background color for active tab */
    /* border-color: var(--primary); */
    /* font-weight: 700; */
}

/* For inactive tabs */
.nav-tabs .nav-link {
    color: var(--dark); /* Dark color for inactive tabs */
    background-color: var(--light); /* Optional: Background color for inactive tabs */
}

/* Optional: Add hover effect for inactive tabs */
.nav-tabs .nav-link:hover {
    color: var(--primary); /* Primary color on hover */
    /* font-weight: 700; */
}
