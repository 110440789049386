/* Define a keyframe animation for spinning */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Apply the animation to the .loading class */
.loading {
    animation: spin 4s linear infinite;
}

.global-loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fade-loading {
    border-radius: 50%;
    animation: fade 1.5s ease-in-out infinite;
}

@keyframes fade {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}
