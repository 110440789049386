.sub-container {
    width: 100%;
    height: 830px;
}

.top-places-section {
    max-height: 340px;
    min-height: 340px;
    width: 100%;
    background-color: var(--low-prim);
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.welcome-section {
    height: 350px;
    width: 100%;
}

.container-default-padding {
    padding: 30px 0 30px 0 !important;
    min-height: 50px;
}

.auth-section {
    min-height: 100vh;
    width: 100%;
}

.list-hover:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    transition: 0.3s;
}

.card-hover:hover {
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    cursor: pointer;
    transition: 0.3s;
}

.sidebar-button-active {
    background-color: var(--primary) !important;
    color: var(--white) !important;
}

.cb-border-dashed {
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2341C0B5FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2341C0B5FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2341C0B5FF' stroke-width='2' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2341C0B5FF' stroke-width='2' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23CBDFC6FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e"); */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2313873EFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2313873EFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 12px;
    padding: 5px !important;
    border: none;
}

.profile-upload-dropdown .dropdown-menu {
    top: 10px !important;
}

.univ-image {
    border-radius: 50% !important;
    border: 4px solid var(--primary);
    max-width: 200px;
    max-height: 200px;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-size: cover;
}

#profile-upload-icon {
    position: absolute;
    top: -53px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    padding: 0;
    background: rgba(255, 255, 255, 0.44);
    right: 10px;
}

div.dropdown-menu.show {
    margin-left: -110px !important;
}

.center-dropdown .dropdown-menu {
    left: 0 !important;
}

.dropdown-menu svg {
    margin-right: 10px;
    vertical-align: middle;
    display: none;
}

.dropdown-item:hover,
.dropdown-item {
    color: var(--dark);
    background-color: transparent;
    padding-bottom: 3px;
}

.dropdown-toggle::after {
    display: none !important;
}

.multiSelectContainer input {
    background: transparent !important;
    border: none !important;
    margin-top: 0 !important;
}

.multiSelectContainer li:hover {
    background: var(--primary) !important;
    color: var(--white) !important;
    cursor: pointer !important;
}

.profile-image-small {
    border-radius: 50% !important;
    border: 1px solid var(--primary);
    max-width: 35px;
    max-height: 35px;
    width: 35px;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-size: cover;
}

.profile-image-small-2 {
    border-radius: 50% !important;
    border: 1px solid var(--primary);
    max-width: 35px;
    max-height: 35px;
    width: 35px;
    height: 35px;
    background-position: center;
    background-size: cover;
}

.profile-image-smaller {
    border-radius: 50% !important;
    border: 1px solid var(--primary);
    max-width: 28px;
    max-height: 28px;
    width: 28px;
    height: 28px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-size: cover;
}

ul.react-paginate {
    display: flex;
    justify-content: end;
    list-style-type: none;
    padding: 0;
}
  
ul.react-paginate li {
    margin: 0 3px;
}
  
ul.react-paginate li a {
    padding: 0.5rem 1rem;
    border: var(--border) 1px solid;
    border-radius: 5px;
    cursor: pointer;
    color: var(--primary);
    background-color: var(--white);
    font-family: var(--font-primary);
    font-size: 14px;
    /* transition: background-color 0.3s, color 0.3s; */
}
  
ul.react-paginate li.selected a,
ul.react-paginate li.selected a:hover {
    background-color: var(--primary);
    color: var(--text-light) !important;
}
  
ul.react-paginate li.disabled a {
    color: var(--gray);
}
  
ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
    cursor: default;
}
  
ul.react-paginate li.previous a,
ul.react-paginate li.next a {
    color: var(--primary);
}
  
ul.react-paginate li a:hover {
    /* background-color: var(--low-prim); */
    color: var(--primary);
}
  
.file-upload {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--border);
    cursor: pointer;
}

.uploaded-image {
    border: 1px solid var(--gray);
    border-radius: 10px;
    width: 105px;
    height: 105px;
    max-width: 190px;
    max-height: 190px;
    background-position: center;
    background-size: cover;
}


.top-place-image {
    border-radius: 5px 5px 0 0 !important;
    /* max-width: 200px; */
    max-height: 160px;
    width: 100%;
    height: 160px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-size: cover;
}

.italic-placeholder::placeholder {
    font-style: italic;
}